// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-component[data-v-3753ed86] {
  min-height: 100%;
}
.text-box[data-v-3753ed86] {
  height: 100% !important;
  overflow: hidden;
  outline: none !important;
  font-size: 32px;
  padding-inline: 10px;
  word-wrap: break-word;
  white-space: break-spaces;
}
.text-box u[data-v-3753ed86] * {
  text-decoration: underline;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
