<template>
  <div class="date-widget" :key="refreshKey" :style="[element.styles]">
    {{ formattedDate }}
  </div>
</template>

<script>
export default {
  props: ["element", "styles", "index"],
  data() {
    return {
      timer: null,
      refreshKey: false,
    }
  },
  mounted(){
    this.timer = setInterval(this.updateDate, 1000)
  },
  beforeUnmount(){
    clearInterval(this.timer)
  },
  methods: {
    updateDate(){
      this.refreshKey = !this.refreshKey
    }
  },
  computed: {
    formattedDate() {
      this.refreshKey;
      const formatOptions = {
        default: 'DD/MM/YYYY',
        '1': 'YYYY/MM/DD',
        '2': 'MMMM D, YYYY',
        '3': 'dddd, MMMM D YYYY'
      };
      const formatType = this.element.type;
      return this.$dayjs().tz(this.element.settings.timezone.value).format(formatOptions[formatType]);
    }
  }
}
</script>

<style lang="scss" scoped>
.date-widget {
  border: 1px solid;
  color: white;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
