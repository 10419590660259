<template>
  <div class="background">
    <div class="cache" id="cache"></div>
    <template v-if="ready">
      <transition
        :mode="this.transition.name === 'fade' ? 'out-in' : undefined"
        :enter-active-class="this.transition.name + '-in'"
        :leave-active-class="this.transition.name + '-out'"
      >
      <div :key="this.indexDataArray" class="frame" ref="frame" :style="computedStyle">
        <div class="frame-background" :style="computedStyles" v-if="this.presentation">
          <div id="elements">
            <div v-for="(element, index) in this.presentation.slides[indexDataArray].elements" :key="element.id" :style="{
              position: 'absolute',
              top: element.top + 'px',
              transform: 'rotate(' + element.angle + 'deg)',
              left: element.left + 'px',
              width: element.width + 'px',
              height: element.height + 'px',
            }">
              <component :key="this.refreshKey" :preview="true" :stb="true" :ready="videoReady" :is="element.name" :element="element" :style="element.styles"
                :shape="element" :editor="element.editor" :index="index" :pauser="true" :wayfinder="this.display?.location_id"></component>
            </div>
          </div>
        </div>
      </div>
      </transition>
    </template>
    <template v-else>
      <div></div>
    </template>
  </div>
</template>

<script>
import axios from "axios"

// === Start Widgets === //
import DateWidget from "@/components/composer/widgets/info-services/DateWidget.vue";
import TextWidget from "@/components/composer/widgets/basic/TextWidget.vue";
import ShapesWidget from "@/components/composer/widgets/basic/ShapesWidgets";
import VideoWidget from "@/components/composer/widgets/media/VideoWidget.vue";
import ImageWidget from "@/components/composer/widgets/media/ImageWidget.vue";
import WeatherWidget from "@/components/composer/widgets/info-services/WeatherWidget.vue";
import ClockWidget from "@/components/composer/widgets/info-services/ClockWidget.vue";
import TableWidget from "@/components/composer/widgets/info-services/TableWidget.vue";
import WayFinderWidget from "@/components/composer/widgets/info-services/WayFinderWidget.vue";
import FlightsWidget from "@/components/composer/widgets/info-services/FlightsWidget.vue";
import IFrameWidget from "@/components/composer/widgets/media/IFrameWidget.vue";
import TVWidget from "@/components/composer/widgets/media/TVWidget.vue";
// === End Widgets === //

import default_presentation from "./default_presentation.json";
import default_presentation_portrait from "./default_presentation_portrait.json";

const DEFAULT_TIME = 1; // In hours

export default {
  name: "DisplayView",
  components: {
    ShapesWidget,
    DateWidget,
    TextWidget,
    VideoWidget,
    ImageWidget,
    WeatherWidget,
    ClockWidget,
    TableWidget,
    FlightsWidget,
    WayFinderWidget,
    IFrameWidget,
    TVWidget
  },
  async beforeMount() {
    try {
      // eslint-disable-next-line no-undef
      NBrowser_CachedPlayer.stop();
      // eslint-disable-next-line no-undef
      NBrowser_CachedPlayer.hidePlayer();
    } catch(e){
      console.log(
          '%cNBrowser Ready',
          'color: aqua',
      );
    }


    this.getPresentation();
  },
  data() {
    return {
      filePath: '',
      ready: false,
      videoReady: false,
      display: {},
      presentation: null,
      indexDataArray: 0,
      intervalId: null,
      timezone: "Etc/UTC",

      transition: {
        name: "fade",
        time: 1000,
      },

      slideTimeout: null,
      presentationInterval: null,

      timeout: null,
      portrait: false,
      refreshKey: false,
    };
  },
  created() {
    window.addEventListener('beforeunload', this.reset)  
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.reset)
  },
  watch: {
  },
  methods: {
    reset() {
      clearTimeout(this.slideTimeout);
      clearInterval(this.presentationInterval);

      try {
        // eslint-disable-next-line no-undef
        NBrowser_CachedPlayer.stop();
        // eslint-disable-next-line no-undef
        NBrowser_CachedPlayer.hidePlayer();
      } catch(e){
        console.log(
            '%cNBrowser Player - Reset',
            'color: aqua',
        );
      }
    },
    async getPresentation(){
      let id = this.$route["params"].mac
      this.filePath = '';
      clearTimeout(this.slideTimeout);
      clearInterval(this.presentationInterval);
      
      await axios.post(`/compose/presentation/get_by_mac_address/`,
      {
        "mac_address": id
      })
      .then(async (response) => {
        let result = response.data;
        this.timeout = null;
        this.display = result;
        this.timezone = this.display.device_timezone;

        if(Object.keys(result.presentation).length){
          this.filePath = result.presentation.template["json_file_path"]
        }
        if(Object.keys(result.campaign).length) {
          const startDate = this.$dayjs(result.campaign.start_date);
          const endDate = this.$dayjs(result.campaign.end_date);

          const now = this.$dayjs(this.$dayjs().tz(this.timezone).format('YYYY-MM-DD HH:mm:ss')); // Get the current date and time
          if (now.isBetween(startDate, endDate, "[)", "second")) {
              this.filePath = result.campaign.presentation.template["json_file_path"];
              this.timeout = endDate.diff(now, 'millisecond');
          } else {
              this.timeout = startDate.diff(now, 'millisecond');
          }
          if(this.timeout < 0) this.timeout = DEFAULT_TIME * 60 * 60 * 1000;
        }

        if(!this.filePath){
          return this.loadDefaultPresentation();
        }
      
        const url = this.$addBackendUrl(this.filePath)
        await axios.get(url)
        .then((presentation) => {
          if(!presentation.data) 
            return this.loadDefaultPresentation();
          this.presentation = (presentation.data);
          this.removeHiddenSlides();

          if(this.presentation.slides.length === 0) {
            return this.loadDefaultPresentation();
          }
          this.startPresentation();
        })
        .catch(() => {
          return this.loadDefaultPresentation();
        });
        
      })
      .catch(() => {
        return this.loadDefaultPresentation();
      });
    },
    loadDefaultPresentation(){
      this.presentation = default_presentation;
      if(this.display.device_orientation === "portrait")
        this.presentation = default_presentation_portrait;
      
      this.updateClockWidget(this.timezone);
      if(!this.timeout) this.timeout = DEFAULT_TIME * 60 * 60 * 1000;
        this.startPresentation();
    },
    updateClockWidget(newValue) {
      try {
        this.presentation.slides.forEach((slide) => {
        slide.elements.forEach((widget) => {
          if(widget.name === "ClockWidget")
            widget.settings.timezone.value = newValue; // Replace with the new value
        });
      });
      } catch {
        return;
      }
    },
    removeHiddenSlides() {
      this.presentation.slides = this.presentation.slides.filter(slide => slide.settings.show);
    },
    getImageLinks(slidesData) {
      const imageLinks = [];      
      try {
          slidesData.slides.forEach(slide => {
          if(slide.settings.background.type === 'image' && slide.settings.background.value) {
            imageLinks.push(slide.settings.background.value);
          }

            slide.elements.forEach(element => {
                if (element.name === "ImageWidget" && element.file) {
                    imageLinks.push(element.file);
                }
            });
        });
      }
      catch(err) {
        return []
      }
      
      return imageLinks;
    },
    preloadImage(url){
        var cache = document.getElementById("cache");
        var img = new Image();
        img.src = url;
        img.style = "position:absolute";
        cache.appendChild(img);
    },
    replaceMacros(slidesData, replaceData){
      const replacements = {
          EVENT_LOCATION: replaceData?.device_location,
          EVENT_NAME: replaceData?.conference?.name,
          EVENT_START_DATE: this.$dayjs(replaceData?.conference?.start_time).format('LLL'),
          EVENT_END_DATE: this.$dayjs(replaceData?.conference?.end_time).format('LLL'),
      };

      if(!Object.keys(this.display.conference).length){
        replacements.EVENT_NAME = '&nbsp;'
        replacements.EVENT_START_DATE = '&nbsp;'
        replacements.EVENT_END_DATE = '&nbsp;'
      }

      this.refreshKey = !this.refreshKey;

      try {
        slidesData.slides.forEach(slide => {
          slide.elements.forEach(element => {
            if (element.name === "TextWidget" && element.editor) {
              Object.keys(replacements).forEach(mentionType => {
                const replacementText = replacements[mentionType] || '&nbsp;';
                const regex = new RegExp(`<span class="mention" data-type="mention" data-id="${mentionType}">[^<]*</span>`, 'g');
                
                // Store the previous mentions to avoid repeated replacements
                const matches = element.editor.match(regex);
                if (matches) {
                  matches.forEach(match => {
                    element.editor = element.editor.replace(match, 
                      `<span class="mention" data-type="mention" data-id="${mentionType}">${replacementText}</span>`);
                  });
                }
              });
            }
          });
        });
      } catch (err) {
        console.error(err);
        return [];
      }
    },
    // If the indexDataArray is -1, it means that the transition should be removed, basically the start of the presentation
    async setNewTransition(indexDataArray) {
      return new Promise((resolve) => {
        if (indexDataArray === -1) {
          this.transition.duration = 0;
          return resolve();
        }

        const slide = this.presentation.slides[indexDataArray];
        if (slide && slide.settings && slide.settings.transition && slide.settings.animation_time) {
          this.transition = {
            name: slide.settings.animation || 'fade', 
            duration: parseInt(slide.settings.animation_time) || 1
          };
        } else {
          // Handle the case where settings are missing or incomplete
          this.transition = {
            name: 'fade',  
            duration: 1                
          };
        }

        resolve();
      });
    },
    startPresentation() {
      this.videoReady = false;
      const images = this.getImageLinks(this.presentation);
      images.map(x => this.preloadImage(x))      
      this.indexDataArray = -1;

      if (this.presentation.orientation === 'portrait') {
          this.portrait = true;
      } else {
          this.portrait = false;
      }      

      if(this.display.device_type === 'conference'){
        this.replaceMacros(this.presentation, this.display);
        if(Object.keys(this.display.conference).length){
          const now = this.$dayjs(this.$dayjs().tz(this.timezone).format('YYYY-MM-DD HH:mm:ss'));
          const end_time = this.$dayjs(this.display.conference.end_time).format('YYYY-MM-DD HH:mm')
          if(this.timeout)
            this.timeout = Math.min(this.$dayjs(end_time).diff(now, 'millisecond'), this.timeout);    
          else
            this.timeout = this.$dayjs(end_time).diff(now, 'millisecond');
          }
      }

      this.setNewTransition(-1);
      this.runDynamicInterval();
      this.ready = true;

      if(this.timeout) {
        this.presentationInterval = setInterval(() => {
          this.getPresentation();
        }, this.timeout);
      }
    },
    async runDynamicInterval() {
      if (this.presentation.slides.length > 1)
        this.videoReady = false;

      await this.getIndexArray();

      if (this.presentation.slides.length > 1) {
        await new Promise(resolve => setTimeout(resolve, this.transition.duration * 1000));
        await this.setNewTransition(this.indexDataArray);
      }

      if (!this.videoReady)
        this.videoReady = true;

      if (this.presentation.slides.length > 1) {
        const slide_transition = this.presentation.slides[this.indexDataArray].settings.transition * 1000;

        if (this.clearTimeout) {
          clearTimeout(this.slideTimeout);
        }

        this.slideTimeout = setTimeout(this.runDynamicInterval, slide_transition);
      }
    },
    async getIndexArray() {
      if (this.indexDataArray < this.presentation.slides.length - 1) {
        for (let i = this.indexDataArray + 1; i < this.presentation.slides.length; i++) {
          return this.indexDataArray = i;
        }
      }
      // Reset to the start when the end is reached
      return this.indexDataArray = 0;
    },
  },
  computed: {
    getTimeout(){
      return this.timeout;
    },
    computedStyle() {
      return {
        animationDuration:
          `${this.transition.name === "fade"
            ? this.transition.duration / 2
            : this.transition.duration}s`
        ,
        width: `${this.portrait ? '1080px' : ''}`,
        height: `${this.portrait ? '1920px' : ''}`,
      };
    },
    computedStyles() {
      let type = this.presentation.slides[this.indexDataArray].settings.background.type;
      let value = this.presentation.slides[this.indexDataArray].settings.background.value;
      let style = "none";

      if (type === "color") {
        style = value;
      }
      if (type === "image") {
        style = "url(" + value + ")";
      }

      return {
        backgroundColor: type === "color" ? style : "",
        backgroundImage: type === "image" ? style : "",
        backgroundSize: type === "image" ? "cover" : "",
      };
    }
  },
};
</script>
<style lang="css" scoped>
.background {
  width: 100vw;
  height: 100vw;
  background: black !important;
}

.frame {
  width: 1920px;
  height: 1080px;
  background: white;
  transform-origin: top left;
  -webkit-transform-origin: top left;
  word-wrap: break-word;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0;
}

.frame-background {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  background-position: center;
}

p {
  margin: 0;
  padding: 0;
}
</style>

<style lang="css" scoped>
.cache{
  position:absolute;z-index:-1000;opacity:0;overflow: hidden; 
}

@keyframes slideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.slide-left-in {
  position: absolute;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: slideRight;
}

.slide-left-out {
  position: absolute;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: slideOutRight ;
}


@keyframes slideRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.slide-right-in {
  position: absolute;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: slideLeft;
}

.slide-right-out {
  position: absolute;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: slideOutLeft;
}


@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


.fade-in {
  animation-fill-mode: both;
  animation-name: fade;
}


.fade-out {
  animation-fill-mode: both;
  animation-name: fadeOut;
}

.dissolve-in {
  position: absolute;
  animation-fill-mode: both;
  animation-name: fade;
}

.dissolve-out {
  position: absolute;
  animation-fill-mode: both;
  animation-name: fadeOut;
}

</style>
