// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-box .mention {
  border-radius: unset !important;
  border: unset !important;
  padding: unset !important;
  margin: unset !important;
  box-decoration-break: clone !important;
  -webkit-box-decoration-break: clone !important;
}
.text-box p {
  margin: 0;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
