<template>
  <div class="uploaded-video">
    <template v-if="!stb">
      <video class="video-tag" v-if="preview" autoplay loop muted>
        <source :src="this.file" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <div
        v-else
        id="VideoWidget"
        :style="[styles,{
          'background-image': `${
            this.thumbnail ? `url(${this.thumbnail})` : `url('../video.svg')`
          }`,
          'background-size': `${this.thumbnail ? 'cover' : '100px'}`,
          'background-color': `${this.thumbnail ? '' : '#f4f4f4'}`,
          'background-repeat': 'no-repeat',
          'background-position': 'center',
        }]"
        class="uploaded-video"
      >
        <video class="video-tag" @loadedmetadata="logDuration" :key="this.file" v-if="this.file" muted ref="videoPlayer">
          <source :src="`${this.file}#t=5`" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      
        <div v-if="this.thumbnail !== null" class="video-overlay position-absolute w-100 h-100 d-flex align-items-center justify-content-center"> 
          <i class="fa fa-play-circle fa-4x" aria-hidden="true"></i>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="stb-video"></div>
    </template>
  </div>
</template>

<script>
import { composerStore } from "@/store";

export default {
  name: "video-widget",
  props:["element", "index", "styles", "preview", "stb", "ready"],
  setup() {
    const elementsStore = composerStore();
    return { elementsStore };
  },
  data() {
    return {
      reader: new FileReader(),
      readerFile: true,
      file: this.element.file,
      thumbnail: this.element.file === null ? this.element.src : 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=',
    };
  },
  watch:{
    "element.src"(val){
      this.thumbnail = val ? val : 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    },
    "element.file"(val){
      this.file = val;
      this.thumbnail = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    },
    file(val){
      this.elementsStore.slideElements[this.index].file = val;
    },
    thumbnail(val){
      this.elementsStore.slideElements[this.index].src = val;
    },
    ready: {
      immediate: true,
      handler(val) {
        if(val){
          if(!this.stb) return;
          try {
            // eslint-disable-next-line no-undef
            NBrowser_CachedPlayer.playInWindow(this.file, this.element.left, this.element.top, this.element.width, this.element.height, true);
          } catch(e){
            console.log(
                '%cNBrowser_CachedPlayer - Play',
                'color: lime',
            );
          }
        }
      }
    }
  },
  beforeUnmount(){
    this.stopNBrowser();
  },
  methods: {
    logDuration() {
      this.$nextTick(() => {
        if(this.$refs.videoPlayer)
          this.elementsStore.videoDuration = Math.floor(this.$refs.videoPlayer?.duration) || 10;
      })
    },
    stopNBrowser(){
      if(!this.stb) return;
      try {
        // eslint-disable-next-line no-undef
        NBrowser_CachedPlayer.stop();
        // eslint-disable-next-line no-undef
        NBrowser_CachedPlayer.hidePlayer();
      } catch (e) {
        console.log(
        '%cNBrowser_CachedPlayer - Stop',
        'color: red',
        );
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.uploaded-video {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}
.video-overlay {
  text-shadow: 0 0 3px #000;
  color: white;
}
.stb-video {
  width: 100%;
  height: 100%;
  background: black;
}

video{
  height: inherit;
  width: inherit;
  object-fit: fill;
}
</style>
