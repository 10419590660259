import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core"
import axios from "axios";
import APIRequest from "@/libs/cloud_auth";
import * as htmlToImage from 'html-to-image';
import { nextTick } from "vue";
import { useAppStore } from "../app";

const NEW_DEFAULT = {};

const THEMES = [
  {
    type: 'wayfinder',
    theme:[
        { primary: "#ff6d6d", secondary: "#24b472", txt_primary: "#FFFFFF", txt_secondary: "#FFFFFF" },
        { primary: "#685793", secondary: "#f1aa00", txt_primary: "#FFFFFF", txt_secondary: "#FFFFFF" },
        { primary: "#253444", secondary: "#00af8b", txt_primary: "#FFFFFF", txt_secondary: "#FFFFFF" },
        { primary: "#2543cf", secondary: "#4360e7", txt_primary: "#FFFFFF", txt_secondary: "#FFFFFF" },
        { primary: "#4b372f", secondary: "#b49286", txt_primary: "#FFFFFF", txt_secondary: "#FFFFFF" }
    ]
  },
  {
    type: 'flights',
    theme: [
        { primary: "#2e2e2e", secondary: "#5f5f5f", tertiary: "#3c3c3c", txt_primary: "#ffffff", txt_secondary: "#FFFFFF" },
        { primary: "#daeef9", secondary: "#0069bd", tertiary: "#0091db", txt_primary: "#0069bd", txt_secondary: "#FFFFFF" },
        { primary: "#253444", secondary: "#00af8b", tertiary: "#253444", txt_primary: "#FFFFFF", txt_secondary: "#FFFFFF" },
        { primary: "#cfe7f2", secondary: "#3f3244", tertiary: "#60495A", txt_primary: "#3f3244", txt_secondary: "#FFFFFF" },
        { primary: "#c7ff5c", secondary: "#212121", tertiary: "#2c364a", txt_primary: "#212121", txt_secondary: "#FFFFFF" }
      ]
  }
];

export const composerStore = defineStore("composer", {
  state: () => ({
    mounted: false,
    element: false,
    locked: false,
    interacting: false,
    galleryBucket: 0,
    galleryUpload: false,
    galleryGroup: false,
    presentationDetails: {},
    presentation: NEW_DEFAULT,
    showOverlay: false,
    galleryType: "",
    showMenuSettings: false,
    indexElement: -1,
    indexDataArray: 0,
    configListView: false,
    temp: " ",
    settings: "default",
    preventDelete: false,
    x: 0,
    y: 0,
    color: "#fff",
    zoom: 1,
    zoomStep: 0.1,
    coordinatesX: 0,
    coordinatesY: 0,
    configX: 0,
    configY: 0,
    shape: "square",
    showGrid: useLocalStorage('grid', true),
    showGallery: false,
    showTvChannels: false,
    idInputText: 0,
    videoDuration: 10,
    selectedEditor: null,
    selectedTable: null,
    onEditor: false,
    dropzone: null,
    configSlides: false,
    indexArrayTemp: -1,
    check: false,
    saveDraft: false,
    dragFromGallery: false,
    test: false,
    column_index: 0,
    row_index: 0,
    indexRow: 0,
    indexCell: 0,
    intervalIndex: [],
    intervalIndexReal: [],
    tableEditor: false,
    selectedFolder: '',
    mergeWarning: false,
    merge: false,
    history: [],
    historyIndex: -1,
    historyLimit: 15, // Specify the history limit
    customThemes: THEMES
  }),

  actions: {
    // Will be useful in the future
    async takeThumbnail(){
      const el = document.querySelector("#image-drop-zone")

      try {
        const dataUrl = await htmlToImage.toJpeg(el, {
          backgroundColor: "transparent",
          width: el.clientWidth,
          height: el.clientHeight,
          canvasWidth: el.clientWidth / 2,
          canvasHeight: el.clientHeight / 2,
          imagePlaceholder: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==',
          pixelRatio: 1,
          quality: 0.5,
          cacheBust: true,
          // filter: filter, // Assuming filter is defined elsewhere
          style: {
            transformOrigin: "top left",
            transform: "scale(1)",
          },
          skipAutoScale: true,
        });

        if (dataUrl) {
          console.log(dataUrl);
          return dataUrl
        }
      } catch (error) {
        console.error("Error capturing image:", error);
      }
      return null;
    },
    getTheme(name) {
      return this.customThemes.find(theme => theme.type === name).theme;
    },
    resetHistory() {
      this.history = [];
      this.historyIndex = -1;
    },
    addToHistory() {
      nextTick().then(() => {
        const stateCopy = JSON.parse(JSON.stringify(this.presentation.slides.map(slide => ({
            ...slide,
            elements: slide.elements.map(element => ({ ...element, active: false }))
          }))
        ));

        this.history = this.history.slice(0, this.historyIndex + 1);
        this.history.push(stateCopy);
        this.historyIndex++;

        if (this.history.length > this.historyLimit) {
          this.history.shift();
          this.historyIndex--;
        }
      })
    },
    undo() {
      if (this.canUndo) {
        if(document.querySelector('.vue-drag-resize-rotate.dragging')) return false;

        this.undoRedoCommonTasks();

        const previousState = JSON.parse(
          JSON.stringify(this.history[--this.historyIndex])
        );
        this.presentation.slides = previousState;
      }
    },
    redo() {
      if (this.canRedo) {
        if(document.querySelector('.vue-drag-resize-rotate.dragging')) return false;

        this.undoRedoCommonTasks();

        const nextState = JSON.parse(
          JSON.stringify(this.history[++this.historyIndex])
        );
        this.presentation.slides = nextState;
      }
    },
    undoRedoCommonTasks(){
      if (this.selectedEditor) {
        this.selectedEditor.commands.blur();
        this.selectedEditor.setEditable(false);
      }
      if (this.selectedTable) {
        this.selectedTable.commands.blur();
      }
      this.onEditor = false;
      this.settings = "default";
      this.configSlides = false;
      this.showMenuSettings = false;
      this.indexElement = -1;
    },
    async getTvChannels(){
      return new Promise((resolve, reject) => {
        APIRequest('widgets/tvwidget/?page_size=999999&tenant=' + useAppStore().getCurrentTenant)
        .then((response) => {
          const data = response.data["results"];
          const urlList = [];
          data.forEach((element) => {
            urlList.push({id: element["id"], name: element["name"], value: element["url"]});
          });
          resolve(urlList);
        })
        .catch(() => {
          reject([]);
        });
      })
    },
    async saveTvChannel(name, url){
      return new Promise((resolve, reject) => {
        APIRequest('widgets/tvwidget/', 'post',
          {
            "name": name,
            "url": url,
            "tenant": useAppStore().getCurrentTenant
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
      })
    },
    async updateTvChannel(id, name, url){
      return new Promise((resolve, reject) => {
        APIRequest(`widgets/tvwidget/${id}/`, 'patch',
          {
            "name": name,
            "url": url
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
      })
    },
    async deleteTvChannel(id) {
      return new Promise((resolve, reject) => {
        APIRequest(`widgets/tvwidget/${id}/`, 'delete')
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
      })
    },
    async getIFrameUrls() {
      return new Promise((resolve, reject) => {
        APIRequest('widgets/iframe/?page_size=999999&tenant=' + useAppStore().getCurrentTenant)
            .then((response) => {
              // make a name and value array list
              const data = response.data["results"];
              const urlList = [];
              data.forEach((element) => {
                urlList.push({id: element["id"], name: element["name"], value: element["url"]});
              });

              resolve(urlList);
            })
            .catch(() => {
              reject([]);
            });
      })
    },
    async saveIFrame(name, url){
      return new Promise((resolve, reject) => {
            APIRequest('widgets/iframe/', 'post',
              {
                "name": name,
                "url": url,
                "tenant": useAppStore().getCurrentTenant
              }
            )
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
      })
    },
    async updateIFrame(id, name, url){
      return new Promise((resolve, reject) => {
        APIRequest(`widgets/iframe/${id}/`, 'patch',
          {
            "name": name,
            "url": url
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
      })
    },
    async deleteIFrame(id) {
      return new Promise((resolve, reject) => {
        APIRequest(`widgets/iframe/${id}/`, 'delete')
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
      })
    },
    async openJson(url) {
      return new Promise((resolve, reject) => {
        axios.get(url)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
    async openTemplate(id) {
      return new Promise((resolve, reject) => {
        APIRequest(`/compose/presentation/${id}/`)
            .then((response) => {
              const result = response.data;
              if(result.source_type === 'local' && String(useAppStore().getCurrentTenant) !== String(result.source_ref_id)) {
                reject();
              }
              
              if (result.source_type === 'group' && String(useAppStore().getTenantGroup) !== String(result.source_ref_id)) {
                reject();
              }
                        
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
    },
    async saveTemplate(title) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('template_data.json_file_path',
            new File([JSON.stringify(this.presentation)],
                `${Date.now()}.json`,
                {type: 'application/json'}));

        formData.append('template_data.thumbnail', this.presentation.slides[0].settings.thumbnail);
        formData.append('template_data.orientation', this.presentationDetails.template.orientation);
        formData.append('template_data.type', this.presentationDetails.template.type);
        formData.append('type', this.presentationDetails.type);
        formData.append('name', title);

        APIRequest(`compose/presentation/${this.presentationDetails.id}/`, 'patch', formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              }
            })
            .then(response => {
              // Resolve the Promise with the response data
              resolve(response.data);
            })
            .catch(error => {
              // Reject the Promise with the error
              reject(error);
            });
      });
    },
    addSlide() {
      this.presentation.slides.push({
        id: Date.now(),
        settings: {
          show: true,
          lock: false,
          background: {
            type: "color",
            value: "#fff",
          },
          delay_type: false,
          transition: 10,
          animation: 'fade',
          animation_time: 1,
          thumbnail: "",
        },
        elements: [],
      });
      this.saveDraft = true;
      localStorage.setItem("presentation", JSON.stringify(this.presentation));
    },
    addElement(element) {
      if (
        this.presentation.slides[this.indexDataArray].settings.lock === false
      ) {
        this.presentation.slides[this.indexDataArray].elements.push(element);
        this.saveDraft = true;
        this.indexElement = this.slideElements.length - 1;
        this.showMenuSettings = true;
        localStorage.setItem("presentation", JSON.stringify(this.presentation));
      }
    },
    deleteElement(index) {
      if (
        this.presentation.slides[this.indexDataArray].settings.lock === false
      ) {
        this.presentation.slides[this.indexDataArray].elements.splice(index, 1);
        this.saveDraft = true;
        this.settings = "default";
        this.indexElement = -1;
        this.showMenuSettings = false;
        this.configListView = false;
        this.check = true;
        localStorage.setItem("presentation", JSON.stringify(this.presentation));
      }
    },
    disableElements() {
      this.settings = "default";
      this.showMenuSettings = false;
      this.indexElement = -1;
      for (let i = 0; i < this.slideElements.length; i++) {
          this.slideElements[i].active = false;
      }
    },
  },
  getters: {
    canUndo() {
      return this.historyIndex > 0;
    },
    canRedo() {
      return this.historyIndex < this.history.length - 1;
    },
    slideElements() {
      return this.presentation.slides[this.indexDataArray].elements;
    },
    selectedWidget(){
      return this.slideElements[this.indexElement];
    },
    checkUpdate: (state) => {
      if (state.data !== state.dataTemp) return true;
      return false;
    },
    checkNBrowserWidgets(){
      let found = false;
      this.slideElements.forEach(element => {
        if(['VideoWidget', 'TVWidget'].includes(element.name)) found = true
      })

      if(found) return true
      return false
    },
    hasVideoWidget(){
      if(!this.slideElements) return false;
      if(this.slideElements.find(element => element.name === 'VideoWidget')) return true
      return false;
    },
    currentWidgetNBrowser(){
      if(!this.slideElements[this.indexElement]) return false;
      if(['VideoWidget', 'TVWidget'].includes(this.slideElements[this.indexElement].name)) return true;
      return false;
    },
    hasLockPermissions(){
      return ['client-admin', 'nonius-admin'].includes(useAppStore().getCurrentRole);
    },
    rightMenuOpen(){
      return this.showGallery || this.settings == "TVWidget"
    }
  },
});
