<template>
  <div class="w-100 bg-white min-vh-100 d-flex align-items-center justify-content-center flex-column">
    <img src="loading.gif" width="100" height="100" alt=""/>
    <div>
      <div class="loading-text">
        {{ $t('misc.loadingText') }}
        Redirect
      </div>
      <div class="loading-subtext">
        {{ $t('misc.loadingSubText') }}
      </div>
    </div>
  </div>
</template>

<script>
import {useAppStore} from "@/store";
import {useAuth0} from "@auth0/auth0-vue";

export default {
  setup() {
    const appStore = useAppStore()
    const { isAuthenticated } = useAuth0();

    return { appStore, isAuthenticated }
  },
  mounted(){
    this.$nextTick(() => {
      if(!this.isAuthenticated) {
        return this.$router.replace({
          name: 'login',
        });
      }

      this.$router.replace({
         name: this.appStore.getFirstRoute(),
      });
    })
  }
}
</script>

<style lang="scss" scoped>
.loading-text{
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.5px;
  text-align: center;
  color: #8d8d8d;
}
.loading-subtext{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.5px;
  text-align: center;
  color: #8d8d8d;
}
</style>