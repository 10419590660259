<template>
  <template v-if="!preview">
    <EditorComponent :element="element" v-model:value="value" :style="styles"></EditorComponent>
  </template>
  <template v-else>
    <div class="text-box" v-html="value"></div>
  </template>
</template>

<script>
import { composerStore } from "@/store";
import EditorComponent from "@/components/helpers/EditorComponent.vue"

export default {
  setup() {
    const elementsStore = composerStore();
    return { elementsStore };
  },

  components: {
    EditorComponent
  },
  props:["element", "styles", "index", "preview"],
  data() {
    return {
      value: this.element.editor,
      timeoutId: null,
    };
  },
  computed: {
  },
  methods:{

  }, 
  watch: {
    value(val){
      this.elementsStore.slideElements[this.index].editor = val;
  }, 

}
};
</script>
<style lang="scss" scoped>
.text-box {
  overflow: hidden;
  outline: none !important;
  font-size: 32px;
  padding-inline: 10px;
  word-wrap: break-word;
  white-space: break-spaces;
}
.text-box u * {
  text-decoration: underline;
}
</style>
<style lang="scss">
.text-box .mention {
  border-radius: unset !important;
  border: unset  !important;
  padding: unset  !important;
  margin: unset  !important;
  box-decoration-break: clone  !important;
  -webkit-box-decoration-break: clone  !important;
}
.text-box p {
  margin: 0;
}
</style>