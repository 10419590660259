import {createApp} from "vue";
import {createAuth0} from '@auth0/auth0-vue';
import {createPinia, getActivePinia} from "pinia";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import {setupCalendar, Calendar, DatePicker} from 'v-calendar';
import draggable from "vuedraggable";
import Multiselect from "vue-multiselect";
import i18n from "@/locales/i18n";
import Toast, {TYPE} from "vue-toastification";
import FloatingVue from 'floating-vue'
import VueDragReSizeRotate from "@gausszhou/vue3-drag-resize-rotate";
import dayjs from "dayjs";
import VueVirtualScroller from 'vue-virtual-scroller'

import "./assets/scss/theme.scss";
import "vue-toastification/dist/index.css";
import 'v-calendar/style.css';

// Import Bootstrap functionality
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import 'floating-vue/dist/style.css'

const app = createApp(App);

import "@/plugins/localeDate";
import "@/plugins/cityMap";
import SuccessIcon from './assets/toast/SuccessIcon.vue';
import ErrorIcon from './assets/toast/ErrorIcon.vue';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

app.config.globalProperties.$dayjs = dayjs;

// https://dummyjson.com/auth/
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

app.config.globalProperties.$truncateText = function (text, length, clamp = '...') {
    const content = text.trim();
    return content.length > length ? `${content.slice(0, length)}${clamp}` : content;
};

app.config.globalProperties.$lazyCaller = function(propertyName, value, time = 500) {
    clearTimeout(this._lazyCallerTimeout);
    this._lazyCallerTimeout = setTimeout(() => {
        this[propertyName] = value;
    }, time);
};

// Global Property for replacing http to https to Staging
app.config.globalProperties.$replaceHttpToHttps = function(url) {
    if(process.env.VUE_APP_TITLE !== 'Nonius Signage Staging') return url;
    return url.replace('http://', 'https://');
};


app.config.globalProperties.$addBackendUrl = function(url) {
    return `${process.env.VUE_APP_BACKEND_URL}${url}`;
};

const options = {
    toastDefaults: {
        [TYPE.SUCCESS]: {
            icon: SuccessIcon,
        },
        [TYPE.ERROR]: {
            icon: ErrorIcon,
        },
    },
};

app
    .use(i18n)
    .use(createPinia())
    .use(getActivePinia())
    .use(router)
    .use(createAuth0({
            domain: process.env.VUE_APP_AUTH0_DOMAIN,
            clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
            useRefreshTokens: true,
            cacheLocation: 'localstorage',
            authorizationParams: {
                redirect_uri: process.env.VUE_APP_AUTH0_CALLBACK_URL
            }
        })
    )
    .use(setupCalendar, {})
    .use(Toast, {
        transition: "Vue-Toastification__fade",
        showCloseButtonOnHover: true,
        ...options
    })
    .use(VueDragReSizeRotate)
    .use(FloatingVue,
        {
            themes: {
                'nonius-tooltip': {
                    '$extend': 'tooltip',
                    distance: 10
                },
            },
        }
    )
    .use(VueVirtualScroller)
    .component("Icon", require('./components/IconComponent.vue').default)
    .component("Multiselect", Multiselect)
    .component("Draggable", draggable)
    .component('Calendar', Calendar)
    .component('DatePicker', DatePicker)
    .mount("#app");
