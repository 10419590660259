<template>
  <div class="wrapper" v-if="this.ready">
    <div class="zoom-wrapper">
      <Transition 
          :mode="this.transition.name === 'fade' ? 'out-in' : undefined"
          :enter-active-class="this.transition.name + '-in'"
          :leave-active-class="this.transition.name + '-out'"
        >
      <div
        :key="this.elementsStore.indexDataArray"
        ref="buildView"
        class="buildView"
        :style="computedStyle"
      >
      <div class="frame-background" :style="computedStyles">
        <div id="elements">
          <div
            v-for="(element, index) in this.elements[this.elementsStore.indexDataArray].elements"
            :key="element.id"
            :style="{
              position: 'absolute',
              top: element.top + 'px',
              rotate: element.angle + 'deg',
              left: element.left + 'px',
              width: element.width + 'px',
              height: element.height + 'px',
            }"
          >

            <component
              :preview="true"
              :is="element.name"
              :element="element"
              :style="element.styles"
              :shape="element"
              :editor="element.editor"
              :index="index"
              :pauser="true"
            ></component>

          </div>
        </div>
      </div>
      </div>
      </Transition>
    </div>
  </div>
</template>
<script>
// === Start Widgets === //
import IFrameWidget from "../widgets/media/IFrameWidget.vue";
import DateWidget from "../widgets/info-services/DateWidget.vue";
import TextWidget from "../widgets/basic/TextWidget.vue";
import ShapesWidget from "../widgets/basic/ShapesWidgets";
import VideoWidget from "../widgets/media/VideoWidget.vue";
import ClockWidget from "../widgets/info-services/ClockWidget.vue";
import ImageWidget from "../widgets/media/ImageWidget.vue";
import WeatherWidget from "../widgets/info-services/WeatherWidget.vue";
import TableWidget from "../widgets/info-services/TableWidget.vue"
import FlightsWidget from "../widgets/info-services/FlightsWidget.vue"
import WayFinderWidget from "../widgets/info-services/WayFinderWidget.vue";
import TVWidget from "../widgets/media/TVWidget.vue";
// === END Widgets === //

import {composerStore} from "@/store";

export default {
  setup() {
    const elementsStore = composerStore();

    elementsStore.presentation = JSON.parse(localStorage.getItem("presentation"));

    return { elementsStore };
  },
  name: "buildView",
  components: {
    ShapesWidget,
    DateWidget,
    TextWidget,
    VideoWidget,
    ImageWidget,
    WeatherWidget,
    ClockWidget,
    TableWidget,
    FlightsWidget,
    WayFinderWidget,
    IFrameWidget,
    TVWidget
  },
  data() {
    return {
      elements: this.elementsStore.presentation.slides,
      height: 0,
      width: 0,
      ready: false,
      intervalId: null,
      portrait: false,
      transition: {
        name: "fade",
        time: 1000,
      },
    };
  },
  mounted() {
    if(this.elementsStore.presentation.orientation === "landscape") {
      this.width = 1920
      this.height = 1080
      this.portrait = false;
    }
    else{
      this.width = 1080
      this.height = 1920
      this.portrait = true;
    }
    
    this.elementsStore.indexDataArray = -1
    
    this.removeHiddenSlides();
    if(this.elementsStore.presentation.slides.length === 0) {
      return;
    }
    this.ready = true;
    this.runDynamicInterval();
  },
  computed: {
    computedStyle() {
      return {
        animationDuration:
          `${this.transition.name === "fade"
            ? this.transition.duration / 2
            : this.transition.duration}s`
        ,
        width: `${this.portrait ? '1080px' : ''}`,
        height: `${this.portrait ? '1920px' : ''}`,
      };
    },
    computedStyles() {
      let type = this.elementsStore.presentation.slides[this.elementsStore.indexDataArray].settings.background.type;
      let value = this.elementsStore.presentation.slides[this.elementsStore.indexDataArray].settings.background.value;
      let style = "none";

      if (type === "color") {
        style = value;
      }
      if (type === "image") {
        style = "url(" + value + ")";
      }

      return {
        backgroundColor: type === "color" ? style : "",
        backgroundImage: type === "image" ? style : "",
        backgroundSize: type === "image" ? "cover" : "",
      };
    }
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearTimeout(this.intervalId);
    }
  },
  methods: {
    async setNewTransition(indexDataArray) {
      return new Promise((resolve) => {
        if (indexDataArray === -1) {
          this.transition.duration = 0;
          return resolve();
        }

        const slide = this.elementsStore.presentation.slides[indexDataArray];
        if (slide && slide.settings && slide.settings.transition && slide.settings.animation_time) {
          this.transition = {
            name: slide.settings.animation || 'fade', 
            duration: parseInt(slide.settings.animation_time) || 1
          };
        } else {
          // Handle the case where settings are missing or incomplete
          this.transition = {
            name: 'fade',  
            duration: 1                
          };
        }

        resolve();
      });
    },
    removeHiddenSlides() {
      this.elementsStore.presentation.slides = this.elementsStore.presentation.slides.filter(slide => slide.settings.show);
    },
    async runDynamicInterval() {
      if (this.elementsStore.indexDataArray < this.elementsStore.presentation.slides.length) {
        await this.getIndexArray();

        if(this.elementsStore.presentation.slides.length > 1){
          await new Promise(resolve => setTimeout(resolve, this.transition.duration * 1000));
          await this.setNewTransition(this.elementsStore.indexDataArray);  
        }

        const transition = this.elementsStore.presentation.slides[this.elementsStore.indexDataArray].settings.transition * 1000;
        this.intervalId = setTimeout(this.runDynamicInterval, transition);
      }
    },
    async getIndexArray() {
      if (this.elementsStore.indexDataArray < this.elementsStore.presentation.slides.length - 1) {
        for (let i = this.elementsStore.indexDataArray + 1; i < this.elementsStore.presentation.slides.length; i++) {
          return this.elementsStore.indexDataArray = i;
        }
      }
      // Reset to the start when the end is reached
      return this.elementsStore.indexDataArray = 0;
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  width: 100vw;
  background: black;
}
.zoom-wrapper {
  transform-origin: top left;
  transform: scale(0.5);
}

.buildView {
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  position: absolute;
  transform-origin: top left;
  pointer-events:none;

}

.frame-background {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  background-position: center;
}


@keyframes slideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.slide-left-in {
  position: absolute;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: slideRight;
}

.slide-left-out {
  position: absolute;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: slideOutRight ;
}


@keyframes slideRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.slide-right-in {
  position: absolute;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: slideLeft;
}

.slide-right-out {
  position: absolute;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: slideOutLeft;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


.fade-in {
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: fade;
}


.fade-out {
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: fadeOut;
}

.dissolve-in {
  position: absolute;
  animation-fill-mode: both;
  animation-name: fade;
}

.dissolve-out {
  position: absolute;
  animation-fill-mode: both;
  animation-name: fadeOut;
}


</style>
