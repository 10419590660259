<template>
  <div class="weather"
    :style="[{'background-color':  element.styles.backgroundColor}, element.styles]">
    <WeatherComponent
      v-if="data"
      :element="element"
      :direction="element.type"
      :data="this.data"
      :format="this.element.format === 0 ? 'ºC' : 'ºF'"
      :timezone="this.element.settings.timezone.value"
      :styleWeather="this.element.style"
      :daysOffset="this.element.settings.offsetDays">
    </WeatherComponent>
  </div>
</template>

<script>
import { composerStore } from "@/store";
import axios from "axios"
import WeatherComponent from "./weather-types/WeatherComponent.vue"

const HOURS = 1;
const INTERVAL = HOURS * 3600 * 1000;

export default {
  name: "weather-widget",
  props: ["element", "styles", "index", "preview"],
  components: {
    WeatherComponent,
  },
  setup() {
        const elementsStore = composerStore();
        return { elementsStore };
    },
  data() {
    return {
      intervalId: null,
      data: {
        "forecast_information": {
            "city": "Porto, Portugal",
            "postal_code": "Porto,Portugal",
            "forecast_date": "2024-03-22",
            "current_date_time": "2024-03-22 09:02:27",
            "unit_system": "US"
        },
        "current_conditions": {
            "condition": "Clear",
            "temp_f": "",
            "temp_c": "19",
            "humidity": "Humidity: 56%",
            "icon": "clear.png",
            "wind_condition": "Wind: E at 7 mph",
            "sunrise": "06:30 AM",
            "sunset": "06:51 PM"
        },
        "forecast_conditions": [
            {
                "day_of_week": this.$dayjs().format('ddd'),
                "low": "58",
                "high": "74",
                "icon": "clear.png",
                "condition": "Clear"
            },
            {
                "day_of_week": this.$dayjs().add(1, 'day').format('ddd'),
                "low": "61",
                "high": "75",
                "icon": "rain.png",
                "condition": "Clear"
            },
            {
                "day_of_week": this.$dayjs().add(2, 'day').format('ddd'),
                "low": "57",
                "high": "73",
                "icon": "scattered_showers.png",
                "condition": "Clear"
            },
            {
                "day_of_week": this.$dayjs().add(3, 'day').format('ddd'),
                "low": "59",
                "high": "74",
                "icon": "mostly_cloudy_(day).png",
                "condition": "Clear"
            },
            {
                "day_of_week": this.$dayjs().add(4, 'day').format('ddd'),
                "low": "59",
                "high": "74",
                "icon": "fog.png",
                "condition": "Clear"
            }
        ]
      },
    };
  },
  async mounted() {
    // START - TEMP FIX FOR DEFAULT PRESENTATIONS
    if(this.element.settings.offsetDays > 4 || this.element.settings.offsetDays < 0){
      this.elementsStore.slideElements[this.index].settings.offsetDays = 4;
    }

    if(!this.element.settings.timezone?.name){
      this.elementsStore.slideElements[this.index].settings.timezone = {
        value: "Europe/Lisbon",
        name: "Porto, Portugal"
      }
    }
    // END - TEMP FIX FOR DEFAULT PRESENTATIONS

    if(this.preview){
      await this.dataAPI()

      this.intervalId = setInterval(async () => {
        await this.dataAPI();
      }, INTERVAL);
    }
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    async dataAPI() {
      try {
        const location = this.element.settings.timezone.name
        const [city, country] = location.split(", ")
        axios.get(`city_weather/${city}/${country}/`,{
          headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
          }
        }).then(response => {
          if(response.data["weather"]["forecast_conditions"]) return this.data = response.data["weather"];
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

};
</script>

<style lang="scss" scoped>
.weather {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}</style>