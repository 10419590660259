<template>
  <div class="weather-widget" ref="weatherRef" v-if="this.data && this.data.current_conditions">
    <div class="weather-container" :class="{vertical: direction === 'vertical'}" v-if="this.daysOffset == 0">
      <div class="weather-icon" :style="{width: getZoom(4), height: getZoom(4)}" v-if="data?.current_conditions?.icon">
        <img :src="getIcon(data?.forecast_conditions[daysOffset]?.icon)" />
      </div>
      <div class="weather-info">
          <div class="weather-temp">
            <span :style="{fontSize: getZoom(2)}">{{ celsiusToFahnereit(data?.current_conditions?.temp_c) }}</span>
            <span class="format" :style="{fontSize: getZoom(0.95)}">{{ this.format }}</span></div>
          <template v-if="this.styleWeather !== 1">
            <div v-if="this.styleWeather === 2 || this.styleWeather === 4" :style="{fontSize: getZoom(0.95)}">
              Today
            </div>
            <div class="text-center text-nowrap" :style="{fontSize: getZoom(0.95)}" v-if="this.styleWeather === 3 || this.styleWeather === 4">
              {{ fahnereitToCelsius(data?.forecast_conditions[daysOffset]?.high) }}{{ this.format }} /
              {{ fahnereitToCelsius(data?.forecast_conditions[daysOffset]?.low) }}{{ this.format }}
            </div>
          </template>
      </div>
    </div>

   <div v-if="this.daysOffset > 0" :class="{vertical: direction === 'vertical'}" class="weather-container">
      <span class="weather-temp" :style="{fontSize: getZoom(1.2)}">{{ data?.forecast_conditions[daysOffset]?.day_of_week }}</span>
      <div :style="{width: getZoom(2.7), height: getZoom(2.7)}" class="weather-icon" v-if="(data?.forecast_conditions[daysOffset]?.icon)">
        <img :src="getIcon(data?.forecast_conditions[daysOffset]?.icon)" />
      </div>
    <div class="weather-info">
          <div class="weather-temp">
            <span :style="{fontSize: getZoom(1.4)}">{{fahnereitToCelsius(data?.forecast_conditions[daysOffset]?.high)}}</span>
            <span class="format" :style="{fontSize: getZoom(0.65)}">{{ this.format }}</span>
            <span class="small" :style="{fontSize: getZoom(0.65)}"> / {{fahnereitToCelsius(data?.forecast_conditions[daysOffset]?.low) }}{{ this.format }}</span>
          </div>
      </div>
  </div>
  </div>
</template><script>

export default {
  methods:{
    ampm2military(ampm) {
      return ampm && ampm !== 'NA' ? this.$dayjs(`1/1/1 ${ampm}`).tz(this.timezone).format("HH:mm:00") : null;
    },
    getIcon(img){
        if(!img) return '';

        let icon = img;
        // Get sunrise and sunset times
        const sunrise = this.ampm2military(this.data.current_conditions.sunrise) || '06:00:00';
        const sunset = this.ampm2military(this.data.current_conditions.sunset) || '18:00:00';

        // Get current time
        const currentTime = this.$dayjs();

        // Check if current time is between sunrise and sunset
        const isDay = currentTime.isBetween(
          `${currentTime.format("YYYY-MM-DD")} ${sunrise}`,
          `${currentTime.format("YYYY-MM-DD")} ${sunset}`,
          null,
          '[]'
        );

        switch (icon) {
          case "clear.png":
          case "clear_(night).png":
            icon = isDay ? "clear.png" : "clear_(night).png";
            break;
          case "cloudy.png":
          case "cloudy1_night.png":
            icon = isDay ? "cloudy.png" : "cloudy1_night.png";
            break;
          case "fair_(day).png":
          case "fair_(night).png":
            icon = isDay ? "fair_(day).png" : "fair_(night).png";
            break;
          case "partly_cloudy.png":
          case "partly_cloudy_(night).png":
            icon = isDay ? "partly_cloudy.png" : "partly_cloudy_(night).png";
            break;
          case "mostly_cloudy_(day).png":
          case "mostly_cloudy_(night).png":
            icon = isDay ? "mostly_cloudy_(day).png" : "mostly_cloudy_(night).png";
            break;
        }

        return require(`@/assets/weather/${icon}`)
      }
  },
  computed: {
    getZoom(){
      return (value) => {
        let minH = this.element.min_height;
        let minW = this.element.min_width;

        let val = Math.min(
          this.element.height / (minH),
          this.element.width / (minW)
        )
      
        return value * val + 'em'
      }
       
    },
    fahnereitToCelsius() {
      return (temperature) => { 
        if(this.element.format === 1) return temperature;
        return parseInt((temperature - 32) * 5 / 9)
      }
    },
    celsiusToFahnereit() {
      return (temperature) => { 
        if(this.element.format === 0) return temperature;
        return parseInt((temperature * 9 / 5) + 32)
      }
    }
  },
  data () {
    return {
    }
  },
  components: {  },
  props: ['data', 'element', 'format', 'direction', 'styleWeather', 'daysOffset', 'timezone'],
}
</script>
<style lang="scss" scoped>
.weather-widget {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .weather-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    gap: 5px;

    &.vertical {
      flex-direction: column;
    }

  }
  .weather-icon{
    display: flex;
    width: 90px;
    height: 90px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .weather-info {
    display: flex;
    flex-direction: column;
    gap: 0;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .weather-temp{
    // font-size: 38px;
    line-height: 1.1;
    text-wrap: nowrap;

    .format {
      margin-left: 5px;
      top: -0.7em;
      // font-size: 17px;
      position: relative;
      line-height: 0;
      vertical-align: baseline;
    }

  }
}
</style>